import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Collapse,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import { ExpandLess, ExpandMore, Close, FilterAlt } from '@mui/icons-material';
import styled from 'styled-components';
import { HomeFilters, Library } from '../types';
import {
  SOURCE_OPTIONS,
  OTHERS_OPTIONS,
  ITEM_TYPE_OPTIONS,
  SCANNING_STATUS_OPTIONS,
} from '../utils/constants';
import { getLibrariesDetails } from '../api/library';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { LibraryType } from '../utils/enum';

interface FilterModalProps {
  t: (key: string) => string;
  open: boolean;
  onClose: () => void;
  filters: HomeFilters;
  setFilters: React.Dispatch<React.SetStateAction<HomeFilters>>;
  libraryType: LibraryType;
}

const FilterModal: React.FC<FilterModalProps> = ({
  t,
  open,
  onClose,
  filters,
  setFilters,
  libraryType,
}) => {
  const [tempFilters, setTempFilters] = React.useState({
    itemType: filters.itemType ?? [],
    source: filters.source ?? [],
    scanning: filters.scanning ?? [],
    restrictedItems: filters.restrictedItems ?? null,
    valuableItems: filters.valuableItems ?? null,
    libraries: filters.libraries ?? [],
  });

  console.log('tempFilters', tempFilters);

  type CategoryName = 'Item Type' | 'Scanning Status' | 'Source' | 'Others';

  const categories: { name: CategoryName; options: string[] }[] = [
    { name: 'Item Type', options: ITEM_TYPE_OPTIONS },
    { name: 'Scanning Status', options: SCANNING_STATUS_OPTIONS },
    { name: 'Source', options: SOURCE_OPTIONS },
    { name: 'Others', options: OTHERS_OPTIONS },
  ];

  const [openCategories, setOpenCategories] = React.useState<string[]>([]);
  const authenticatedApiHook = useAuthenticatedRequest();
  const [libraries, setLibraries] = React.useState<Library[]>([]);

  useEffect(() => {
    const fetchLibraries = async () => {
      const librariesResponses =
        await getLibrariesDetails(authenticatedApiHook);
      const librariesData: Library[] = librariesResponses.map(
        (library: Library) => ({
          id: library.id,
          name: library.name,
        })
      );
      setLibraries(librariesData);
    };

    if (libraryType === LibraryType.ALL_LIBRARIES) {
      fetchLibraries();
    }
  }, [libraryType]);

  const toggleCategory = (category: string) => {
    setOpenCategories(prev =>
      prev.includes(category)
        ? prev.filter(item => item !== category)
        : [...prev, category]
    );
  };

  const categoryMapping: Record<
    'Item Type' | 'Scanning Status' | 'Source',
    keyof HomeFilters
  > = {
    'Item Type': 'itemType',
    'Scanning Status': 'scanning',
    Source: 'source',
  };

  const handleCheckboxChange = (category: CategoryName, option: string) => {
    if (category === 'Others') {
      // Handle restrictedItems and valuableItems separately
      setTempFilters(prev => ({
        ...prev,
        restrictedItems:
          option === 'Restricted Item'
            ? prev.restrictedItems === 'yes'
              ? null
              : 'yes'
            : prev.restrictedItems,
        valuableItems:
          option === 'Valuable Item'
            ? prev.valuableItems === 'yes'
              ? null
              : 'yes'
            : prev.valuableItems,
      }));
    } else {
      // Category is one of 'Item Type', 'Scanning Status', 'Source'
      const key = categoryMapping[category];
      if (key) {
        setTempFilters(prev => {
          const currentValues = prev[key];
          if (Array.isArray(currentValues)) {
            return {
              ...prev,
              [key]: currentValues.includes(option)
                ? currentValues.filter(item => item !== option)
                : [...currentValues, option],
            };
          }
          return prev;
        });
      }
    }
  };

  const handleApply = () => {
    setFilters(tempFilters);
    onClose();
  };

  const handleClear = () => {
    setTempFilters({
      itemType: [],
      source: [],
      scanning: [],
      restrictedItems: null,
      valuableItems: null,
      libraries: [],
    });
    setFilters({
      itemType: [],
      source: [],
      scanning: [],
      restrictedItems: null,
      valuableItems: null,
      libraries: [],
    });
  };

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledBox>
        <Header>
          <Box display="flex" alignItems="center" gap="8px">
            <FilterAlt sx={{ color: '#242424' }} />
            <Typography variant="h6" sx={{ color: '#242424' }}>
              Filters
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <Close sx={{ color: '#242424' }} />
          </IconButton>
        </Header>
        <Divider />
        <FilterContent>
          {categories.map(category => (
            <Box key={category.name}>
              <CategoryHeader onClick={() => toggleCategory(category.name)}>
                <Typography variant="subtitle1">{t(category.name)}</Typography>
                <IconButton size="small">
                  {openCategories.includes(category.name) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </CategoryHeader>
              <Collapse in={openCategories.includes(category.name)}>
                {category.options.map(option => (
                  <FormControlLabel
                    key={option}
                    style={{ display: 'block' }}
                    control={
                      <Checkbox
                        checked={
                          (category.name === 'Item Type' &&
                            tempFilters.itemType.includes(option)) ||
                          (category.name === 'Source' &&
                            tempFilters.source.includes(option)) ||
                          (category.name === 'Scanning Status' &&
                            tempFilters.scanning.includes(option)) ||
                          (category.name === 'Others' &&
                            ((option === 'Restricted Item' &&
                              tempFilters.restrictedItems === 'yes') ||
                              (option === 'Valuable Item' &&
                                tempFilters.valuableItems === 'yes')))
                        }
                        onChange={() =>
                          handleCheckboxChange(category.name, option)
                        }
                      />
                    }
                    label={t(option)}
                  />
                ))}
              </Collapse>
              <Divider />
            </Box>
          ))}
          {libraryType === LibraryType.ALL_LIBRARIES && (
            <Box>
              <Autocomplete
                multiple
                options={libraries}
                getOptionLabel={option => option.name}
                value={tempFilters.libraries}
                onChange={(_, newValue) => {
                  setTempFilters(prev => ({
                    ...prev,
                    libraries: newValue,
                  }));
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t('Libraries')}
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.id}-${option.name}`}>
                    {option.name}
                  </li>
                )}
              />
              <Divider />
            </Box>
          )}
        </FilterContent>
        <StickyFooter>
          <StyledClearButton onClick={handleClear}>
            {t('Clear')}
          </StyledClearButton>
          <StyledApplyButton variant="contained" onClick={handleApply}>
            {t('Apply')}
          </StyledApplyButton>
        </StickyFooter>
      </StyledBox>
    </StyledModal>
  );
};

export default FilterModal;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const StyledBox = styled(Box)`
  width: 100%;
  padding: 16px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  max-height: 90%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const FilterContent = styled(Box)`
  flex: 1;
  overflow-y: auto;
  margin-top: 16px;
`;

const CategoryHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const StickyFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  background-color: white;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  z-index: 10;
`;

const StyledApplyButton = styled(Button)`
  width: 157px;
  height: 44px;
  border-radius: 26px !important;
  padding: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29.59px;
  letter-spacing: 6%;
  text-align: center;
  background-color: #f95656;
  color: white;
  text-transform: none;

  &:hover {
    background-color: #f95656;
    opacity: 0.9;
  }
`;

const StyledClearButton = styled(Button)`
  width: 157px;
  height: 44px;
  font-family: 'Monlam-Bodyig', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 29.59px;
  letter-spacing: 6%;
  text-align: center;
  color: #f95656;
  border: 1px solid #f95656;
  border-radius: 26px; /* Fully rounded */
  text-transform: none;

  &:hover {
    background-color: transparent;
    color: #ff6868;
  }
`;
