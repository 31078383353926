import React, { useState } from 'react';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import {
  FilterAlt as FilterAltIcon,
  GridView as GridViewIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import FilterModal from './FilterBookData';
import { HomeFilters } from '../types';
import { LibraryType } from '../utils/enum';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface CataloguedItemsProps {
  t: (key: string) => string;
  items: any[];
  totalBookCount: number;
  loading: boolean;
  filters: HomeFilters;
  setFilters: React.Dispatch<React.SetStateAction<HomeFilters>>;
  isGridView: boolean;
  handleToggleView: () => void;
  libraryType: LibraryType;
}

const CataloguedItems: React.FC<CataloguedItemsProps> = ({
  t,
  totalBookCount,
  filters,
  setFilters,
  isGridView,
  handleToggleView,
  libraryType,
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const isFilterApplied = (): boolean => {
    return (
      (filters.itemType?.length ?? 0) > 0 ||
      (filters.source?.length ?? 0) > 0 ||
      (filters.scanning?.length ?? 0) > 0 ||
      filters.restrictedItems !== null ||
      filters.valuableItems !== null ||
      (filters.libraries?.length ?? 0) > 0
    );
  };

  return (
    <StyledBox>
      <StyledBoxInner>
        <StyledTypography>{t('Catalogued Items')}</StyledTypography>
        <StyledChip label={String(totalBookCount)} />
      </StyledBoxInner>
      <Box>
        <StyledIconButton onClick={() => setIsFilterModalOpen(true)}>
          <FilterAltIcon
            style={{ color: isFilterApplied() ? '#f95656' : 'inherit' }}
          />
        </StyledIconButton>
        <IconButton onClick={handleToggleView}>
          {isGridView ? <ViewListIcon /> : <GridViewIcon />}
        </IconButton>
      </Box>
      {isFilterModalOpen && (
        <FilterModal
          t={t}
          open={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          filters={filters}
          setFilters={setFilters}
          libraryType={libraryType}
        />
      )}
    </StyledBox>
  );
};

export default CataloguedItems;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const StyledBoxInner = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  height: 24px;
  margin: 0 16px;
  background-color: #d1dbe9;
  color: #1458bd;
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 16px;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    text-transform: none;
  }
`;
