import React, {useEffect, useState} from 'react'
import logo from '../assets/img/Frame.svg';
import loginimg from '../assets/img/login.png';
import { Button, Container, Typography, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, Box } from '@mui/material';
import { Visibility, VisibilityOff, Person } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { LOGIN_API_ENDPOINT } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../components/SnackbarProvider';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const { setAuthData, authData, login } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authenticatedApiHook = useAuthenticatedRequest();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
      if (authData !== null) {
        console.log(authData)
        navigate("/");
      }
    }, [])

    const handleLogin = async () => {
      try {
        const response = await authenticatedApiHook(LOGIN_API_ENDPOINT,'POST',undefined,{
          username: username.toLowerCase(),
          password: password
        });
        if(response){
          const data = await response.json();
          setAuthData(data); 
          login(data);
          console.log(data)
          if(data.user.role=='lsa' && data.user.has_library == false){
            // TODO: Make the storing token async and await to redirect
            window.location.href = '/library-info';
            // window.location.reload();
          } else if (data.user.role !='lsa' && data.user.has_library == false){
            alert('Non-admin librarian without library. Please contact support.');
          }
          else {
            window.location.href = '/landing';
            window.location.reload();
          }
        }
      } catch (error) {
        if (!navigator.onLine) {
          setErrorMessage('No internet connection. Please check your network.');
        } else {
          setErrorMessage('Incorrect username or password');
          console.error('Login failed', error);
        }
      }
    };
    
  return (
    <Container disableGutters maxWidth={false} sx={{height: '100vh', paddingTop: '2rem', textAlign: 'center'}}>
      <img src={logo} />
      <Typography variant='h5' component='h2' color={'#6D2312'} fontSize={24} fontWeight={600} my={2}>
        {t('Welcome')}
      </Typography>

      <Typography variant='h5' component='h2' color={'#6D2312'} fontSize={24} fontWeight={500}>
          {t('Tibetan Library Management System')}
      </Typography>

      <Typography variant='h5' component='h2' fontSize={16} fontWeight={300} mt={6} mx={0.4} textAlign={'left'} px={'2rem'}>
          {t('Login using provided credentials')}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }} px={'2rem'} mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel sx={{fontWeight: 400, color: '#949494'}} htmlFor="outlined-adornment-username">{t("Username")}</InputLabel>
          <OutlinedInput
            inputProps={{
              autoCapitalize: 'none',
            }}
            id="outlined-adornment-username"
            value={username} 
            onChange={(e) => {setUsername(e.target.value.trim()); setErrorMessage('')}}
            sx={{background: 'white', borderRadius: '12px'}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton disabled sx={{
                    "&.Mui-disabled": {
                        color: "#949494"
                    }
                }} edge='end' disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}>
                    <Person />
                </IconButton>
              </InputAdornment>
            }
            label={t("Username")}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel sx={{ fontWeight: 400, color: '#949494' }} htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
          <OutlinedInput
            autoCapitalize="off"
            id="outlined-adornment-password"
            sx={{background: 'white', borderRadius: '12px'}}
            type={showPassword ? 'text' : 'password'}
            value={password} 
            onChange={(e) => {setPassword(e.target.value.trim()); setErrorMessage('')}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                  color='info'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={t("Password")}
          />
        </FormControl>
        
        {errorMessage !== '' &&<Typography variant='h5' component='h2' fontSize={16} fontWeight={300} mx={0.4} textAlign={'left'} color='error'>
            {t(errorMessage)}
        </Typography>}

      </Box>

      <Box sx={{ position: 'relative', width: '100%' }} mt={8}>
        <img src={loginimg} alt="Login" style={{ width: '100%', opacity: 0.3}} />
      </Box>
      <Button variant="contained" onClick={handleLogin} sx={{textTransform: 'none', py: 2, borderRadius: 150, position: 'absolute', bottom: '5vh', width: '90%', left: '50%', transform: 'translateX(-50%)', maxWidth: '328px'}}>
          <Typography fontSize={16} fontWeight={400} color='white' letterSpacing={'0.96px'}>
              {t('Log In')}
          </Typography>
      </Button>
    </Container>
  )
}
