import React, { useCallback } from 'react';
import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  debounce,
} from '@mui/material';
import { SearchOutlined as SearchOutlinedIcon } from '@mui/icons-material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { analyticsLogEvent } from '../utils/analytics';

interface CoverProps {
  cover: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  t: (key: string) => string;
}

const HomeCover: React.FC<CoverProps> = ({ cover, search, setSearch, t }) => {
  const navigate = useNavigate();

  const handleSearchChange = useCallback(
    debounce((query: string) => {
      if (query.trim()) {
        analyticsLogEvent('Home Page', 'Search', 'Search', query);
      }
    }, 500),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    handleSearchChange(value);
  };

  return (
    <StyledBox cover={cover}>
      <StyledFormControl variant="outlined">
        <StyledOutlinedInput
          value={search}
          onChange={handleInputChange}
          id="search"
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disableTouchRipple
                disableRipple
                disableFocusRipple
                sx={{ paddingRight: 0 }}
                onClick={() => navigate('/scan')}
              >
                <QrCodeScannerOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
          placeholder={t('Search resources')}
          inputProps={{ style: { padding: '14px 16px', textAlign: 'left' } }}
        />
      </StyledFormControl>
    </StyledBox>
  );
};

export default HomeCover;

interface StyledBoxProps {
  cover: string;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  background-image: url(${props => props.cover});
  max-width: 100%;
  align-items: center;
  height: 86px;
  text-align: center;
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 16px 0;
    width: -webkit-fill-available;
    padding: 0 16px;
  }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  && {
    background: white;
    border-radius: 11px;
    font-size: 14px;
    font-family: 'Noto Sans Variable';
  }
`;
