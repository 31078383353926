import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getLibraryDetails,
  getStates,
  setLibraryDetails,
} from '../api/library';
import { useLocation, useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import LibraryDetails from '../components/LibraryDetails';
import LibraryStatistics from '../components/LibraryStatistics';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import cover from '../assets/img/libinfonew.png';
import logo from '../assets/img/logonew.png';
import { useAuth } from '../context/AuthContext';
import useSnackbar from '../hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { State } from '../types';

export default function LibraryInfo() {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [libraryName, setLibraryName] = useState<string | null>(null);
  const [shortName, setShortName] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [geolocation, setGeoLocation] = useState<string | null>(null);
  const [numBooks, setNumBooks] = useState<string>('');
  const [numManuscripts, setNumManuscripts] = useState<string>('');
  const [numShelves, setNumShelves] = useState<string>('');
  const [numMiscItems, setNumMiscItems] = useState<string>('');
  const [numRooms, setNumRooms] = useState<string>('');
  const [numWoodblockPrints, setNumWoodblockPrints] = useState<string>('');
  const [numFloors, setNumFloors] = useState<string>('');
  const [state, setState] = useState<string | null>('1');
  const [dialog, setDialog] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const { open, error, setError, handleClose, handleClick } = useSnackbar();
  const navigate = useNavigate();
  const params = useLocation();
  const { logout } = useAuth();
  const authenticatedApiHook = useAuthenticatedRequest();
  const [statesList, setStatesList] = useState<State[]>([]);
  const [libraryState, setLibraryState] = React.useState<string>('');

  useEffect(() => {
    const fetchStates = async () => {
      const response = await getStates(authenticatedApiHook);
      setStatesList(response);
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (params.state?.isEdit) {
      (async () => {
        try {
          const details = await getLibraryDetails();
          setSelectedImage(details?.logo);
          setGeoLocation(details?.geo_location);
          setLocation(details?.address);
          setLibraryName(details?.name);
          setShortName(details?.alternate_name);
          setNumBooks(details?.book_count);
          setNumManuscripts(details?.manuscript_count);
          setNumShelves(details?.shelf_count);
          setNumMiscItems(details?.misc_count);
          setNumFloors(details?.floor_count);
          setNumWoodblockPrints(details?.woodblock_count);
          setNumRooms(details?.room_count);
          setLibraryState(details?.state || '');
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [params.state?.isEdit]);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target || !event.target.files) {
      setError('No file selected');
      handleClick();
      return;
    }
    const file = event.target.files[0];
    if (!file) return;

    if (file.type.startsWith('video/')) {
      setError('Selected file is a video. Please choose an image file.');
      handleClick();
      return;
    }

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1280,
      useWebWorker: true,
    };

    try {
      const compressedBlob = await imageCompression(file, options);
      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
        lastModified: Date.now(),
      });
      setFile(compressedFile);

      const reader = new FileReader();
      reader.onload = e => {
        if (typeof e.target?.result === 'string') {
          const mimeType = e.target.result.split(',')[0].split(':')[1];
          setSelectedImage(
            `data:${mimeType};base64,${e.target.result.split(',')[1]}`
          );
        }
      };
      reader.onerror = error => {
        console.error('File reading error:', error);
        setError('There was an error reading the file. Please try again.');
        handleClick();
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Image compression error:', error);
      setError('There was an error compressing the image. Please try again.');
      handleClick();
    }
  };

  const handleBack = () => {
    if (state === '1') {
      if (params.state && params.state.isEdit) {
        navigate('/landing');
      } else {
        setDialog(true);
      }
    } else if (state === '2') {
      setState('1');
    } else {
      setState('1');
    }
  };

  const handleNext = async () => {
    let logo;
    if (file === null && selectedImage === null) {
      // Condition 1: If file is null and selectedImage, i.e when image is removed is also null, send `null`
      logo = null;
    } else if (file === null && selectedImage !== null) {
      // Condition 2: If file is null and selectedImage is not null i.e when image is not touched, do not send the `logo` key
      logo = undefined;
    } else if (file !== undefined && file !== null) {
      // Condition 3: If file is defined (not undefined or null), send the file
      logo = file;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let libraryDetails: any = {
      name: libraryName || '',
      unique_id: '',
      alternate_name: shortName || '',
      address: location || '',
      geo_location: geolocation || '',
      phone: '',
      logo: logo,
      book_count: numBooks,
      manuscript_count: numManuscripts,
      shelf_count: numShelves,
      misc_count: numMiscItems,
      room_count: numRooms,
      floor_count: numFloors,
      woodblock_count: numWoodblockPrints,
      state: libraryState,
    };
    /* eslint-disable @typescript-eslint/no-unused-vars */
    libraryDetails = Object.fromEntries(
      Object.entries(libraryDetails).filter(
        ([_, value]) => value != null && value !== ''
      )
    );
    /* eslint-disable @typescript-eslint/no-unused-vars */
    try {
      if (state === '1') {
        if (
          libraryName &&
          shortName &&
          location &&
          libraryName.trim() &&
          shortName.trim() &&
          location.trim()
        ) {
          if (!params.state) {
            setSubmitDisabled(true);
            try {
              await setLibraryDetails(authenticatedApiHook, libraryDetails);
              setError('Changes saved successfully!');
              handleClick();
              setTimeout(() => {
                setSubmitDisabled(false);
                navigate('/landing');
              }, 1000);
            } catch (error) {
              setSubmitDisabled(false);
              setError('Failed to save library details. Please try again.');
              handleClick();
            }
          } else {
            setState('2');
          }
        } else {
          setError('The required fields are missing!');
          handleClick();
        }
      } else if (state === '2') {
        setSubmitDisabled(true);
        try {
          await setLibraryDetails(authenticatedApiHook, libraryDetails);
          setError('Changes saved successfully!');
          handleClick();
          setTimeout(() => {
            setSubmitDisabled(false);
            navigate('/landing');
          }, 1000);
        } catch (error) {
          setSubmitDisabled(false);
          setError('Failed to save library details. Please try again.');
          handleClick();
        }
      } else {
        setState('1');
      }
    } catch (error) {
      setSubmitDisabled(false);
      setError('Failed to save library details. Please try again.');
      handleClick();
    }
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          try {
            const { latitude, longitude } = position.coords;
            setGeoLocation(`${latitude}, ${longitude}`);
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch address');
            const data = await response.json();
            setLocation(data.display_name);
            const responseState = data.address.state;
            if (responseState) {
              const matchedState = statesList.find(
                state =>
                  state.name.toLowerCase() === responseState.toLowerCase()
              );
              if (matchedState) {
                setLibraryState(matchedState.id.toString());
              } else {
                setLibraryState('');
              }
            } else {
              setError('State is not defined in the response.');
              setLibraryState('');
            }
          } catch (error) {
            console.error('Error fetching address:', error);
            setError(
              'Address details cannot be fetched. Please type address manually.'
            );
            handleClick();
          }
        },
        error => {
          console.error('Error getting geolocation:', error);
          setError(
            'Geolocation is not available. Please type address manually.'
          );
          handleClick();
        }
      );
    } else {
      setError(
        'Geolocation is not supported by this browser. Please type address manually.'
      );
      handleClick();
    }
  };

  return (
    <Container
      disableGutters
      sx={{ height: '100vh', overflowY: 'scroll', pb: 8 }}
    >
      <Box
        sx={{
          backgroundImage: `url(${cover})`,
          maxWidth: '100%',
          alignItems: 'flex-start',
        }}
      >
        <img src={logo} style={{ padding: '16px' }} />
        <Typography
          sx={{
            padding: '16px',
            fontWeight: 700,
            fontSize: 20,
            color: 'white',
            letterSpacing: '0.7px',
            mt: 1,
          }}
        >
          {t('Create your Library Profile')}
        </Typography>
      </Box>

      {state === '1' ? (
        <LibraryDetails
          selectedImage={selectedImage}
          handleImageChange={handleImageChange}
          setSelectedImage={setSelectedImage}
          libraryName={libraryName}
          setLibraryName={setLibraryName}
          shortName={shortName}
          setShortName={setShortName}
          location={location}
          setLocation={setLocation}
          handleLocationClick={handleLocationClick}
          geolocation={geolocation}
          setGeoLocation={setGeoLocation}
          statesList={statesList}
          libraryState={libraryState}
          setLibraryState={setLibraryState}
        />
      ) : (
        <LibraryStatistics
          numBooks={numBooks}
          setNumBooks={setNumBooks}
          numManuscripts={numManuscripts}
          setNumManuscripts={setNumManuscripts}
          numShelves={numShelves}
          setNumShelves={setNumShelves}
          numMiscItems={numMiscItems}
          setNumMiscItems={setNumMiscItems}
          numRooms={numRooms}
          setNumRooms={setNumRooms}
          numWoodblockPrints={numWoodblockPrints}
          setNumWoodblockPrints={setNumWoodblockPrints}
          numFloors={numFloors}
          setNumFloors={setNumFloors}
        />
      )}

      <Box
        sx={{
          zIndex: 999,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          maxWidth: 'inherit',
          padding: '16px',
          backgroundColor: 'white',
        }}
      >
        <Button
          onClick={handleBack}
          variant="text"
          sx={{
            textTransform: 'none',
            py: 2,
            width: '50%',
            maxWidth: '164px',
            flexDirection: 'row',
          }}
        >
          <WestOutlinedIcon color="secondary" />
          <Typography
            fontSize={16}
            fontWeight={400}
            color="#242424"
            letterSpacing={'0.96px'}
            ml={1}
          >
            {t('Back')}
          </Typography>
        </Button>
        <Button
          disabled={submitDisabled}
          onClick={handleNext}
          variant="contained"
          sx={{
            textTransform: 'none',
            py: 2,
            borderRadius: 150,
            width: '50%',
            maxWidth: '164px',
            flexDirection: 'row',
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={400}
            color="white"
            letterSpacing={'0.96px'}
            mr={1}
          >
            {state === '1' ? t('Next') : t('Save')}
          </Typography>
          <EastOutlinedIcon />
        </Button>
      </Box>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <Typography color={'white'}>{error}</Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you wish to log out?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>No</Button>
          <Button
            onClick={() => {
              setDialog(false);
              logout();
              navigate('/login');
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
