import React, { useState, useMemo } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import DraftsTwoToneIcon from '@mui/icons-material/DraftsTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { createUserFeedback } from '../api/user';
import { feedbackData } from '../types';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { useSnackbar } from '../components/SnackbarProvider';
const HelpSection: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<'main' | 'faqs' | 'feedback'>(
    'main'
  );
  const [feedback, setFeedback] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation('faq');
  const authenticatedApiHook = useAuthenticatedRequest();
  const { showMessage } = useSnackbar();
  const faqKeys = [
    'library_help',
    'item_types',
    'library_short_name',
    'shelf_vs_accession_qr',
    'head_librarian_manage_associates',
    'isbn_scan_issue',
    'multi_volume_collection',
    'item_copy_volume_edition',
    'save_volumes_in_collection',
    'volume_number',
    'edition_publisher_year_place_of_publication',
    'additional_comment',
    'scanning_status',
    'call_number',
    'cataloging_all_copies',
    'finding_cataloged_copy',
    'edit_item_and_copy_details',
    'archive_item',
    'archive_process',
    'find_archived_item',
    'unarchive_accidental_archived_item',
    'delete_permanently_item',
    'difference_archiving_deleting_item',
    'find_books_cataloged_by_me',
    'find_books_on_one_shelf',
    'find_items_by_author_or_publisher',
    'switch_grid_to_list_view',
    'download_cataloged_items_details',
    'change_password',
  ];

  const filteredFAQs = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return faqKeys
      .map(key => ({
        key,
        question: t(`${key}.question`),
        answer: t(`${key}.answer`),
      }))
      .filter(
        faq =>
          faq.question.toLowerCase().includes(query) ||
          faq.answer.toLowerCase().includes(query)
      );
  }, [faqKeys, searchQuery]);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateIndianPhoneNumber = (phone: string) => {
    return /^[6-9]\d{0,9}$/.test(phone);
  };
  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    setIsEmailValid(emailInput === '' || validateEmail(emailInput));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneInput = e.target.value;
    if (/^\d*$/.test(phoneInput) && phoneInput.length <= 10) {
      setPhoneNumber(phoneInput);
      setIsPhoneNumberValid(
        phoneInput === '' ||
          (phoneInput.length === 10 && validateIndianPhoneNumber(phoneInput))
      );
    }
  };

  const isButtonEnabled =
    feedback.trim().length > 0 &&
    feedback.trim().length <= 1000 &&
    isEmailValid &&
    isPhoneNumberValid;

  const handleSendFeedback = async () => {
    const feedbackData: feedbackData = {
      feedback,
      ...(email ? { email } : {}),
      ...(phoneNumber ? { phone: phoneNumber } : {}),
    };
    const response = await createUserFeedback(
      authenticatedApiHook,
      feedbackData
    );
    if (response) {
      showMessage('Feedback Sent! Thank You', 'success', 4000);
    }
    setFeedback('');
    setEmail('');
    setPhoneNumber('');
    setCurrentPage('main');
  };

  const renderHeader = (title: string) => (
    <Box
      display={'flex'}
      flexDirection={'row'}
      p={'16px'}
      bgcolor={'white'}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <IconButton onClick={() => setCurrentPage('main')}>
        <KeyboardArrowLeft />
      </IconButton>
      <Typography fontSize={'16px'} fontWeight={500}>
        {title}
      </Typography>
      <Box width={'30px'}></Box>
    </Box>
  );

  const renderMainPage = () => (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        m: 0,
        p: 0,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        p={'16px'}
        bgcolor={'white'}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
          Help & Support
        </Typography>
        <Box width={'30px'}></Box>
      </Box>
      <Box>
        <Typography
          fontWeight={600}
          fontSize={'20px'}
          textAlign="center"
          marginBottom={'20px'}
          gutterBottom
        >
          How can we help you?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            startIcon={<DraftsTwoToneIcon />}
            sx={{
              mb: 3,
              backgroundColor: '#f0f4ff',
              color: '#4579FF',
              width: '90%',
              textTransform: 'none',
              borderRadius: '11px',
              fontWeight: 400,
              fontSize: '14px',
              ':hover': { backgroundColor: '#f0f4ff' },
            }}
            onClick={() => setCurrentPage('feedback')}
          >
            Send us your feedback
          </Button>
        </Box>

        <Box px={'16px'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pb={1}
            onClick={() => setCurrentPage('faqs')}
          >
            <Typography fontWeight={500} fontSize={'16px'}>
              Frequently Asked Questions
            </Typography>
            <IconButton sx={{ paddingRight: 0 }}>
              <Typography fontWeight={400} fontSize={'14px'} color={'#4579FF'}>
                View all
              </Typography>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 2 }}>
          {faqKeys && faqKeys.length > 0 ? (
            faqKeys.slice(0, 4).map((key, index) => {
              const questionKey = `${key}.question`;
              const answerKey = `${key}.answer`;
              const question = t(questionKey);
              const answer = t(answerKey);

              // If no question or answer is found, skip this entry
              if (!question || !answer) {
                return null;
              }

              return (
                <Accordion key={index} sx={{ mb: 1, borderRadius: '8px' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      fontWeight={600}
                      fontSize="14px"
                      color="#242424"
                    >
                      <ReactMarkdown>{question}</ReactMarkdown>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      fontSize="14px"
                      color="#949494"
                    >
                      <ReactMarkdown>{answer}</ReactMarkdown>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Typography>No FAQs available.</Typography>
          )}
        </Box>
      </Box>
    </Container>
  );

  const renderFAQsPage = () => (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        m: 0,
        p: 0,
      }}
    >
      {renderHeader('Frequently Asked Questions')}
      <Box sx={{ p: 3, flexShrink: 0 }}>
        <TextField
          label="Search FAQs"
          variant="outlined"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', px: 2 }}>
        {filteredFAQs.length > 0 ? (
          filteredFAQs.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 1, borderRadius: '8px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={600} fontSize="14px">
                  <ReactMarkdown>{faq.question}</ReactMarkdown>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <ReactMarkdown>{faq.answer}</ReactMarkdown>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>No FAQs found</Typography>
        )}
      </Box>
    </Container>
  );

  const renderFeedbackPage = () => (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        m: 0,
        p: 0,
      }}
    >
      {renderHeader('Feedback')}
      <Box sx={{ p: 3 }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '16px',
            color: '#494949',
            font: 'inter',
          }}
          gutterBottom
        >
          Please share your feedback*
        </Typography>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#242424',
            font: 'inter',
          }}
          gutterBottom
        >
          Please share your thoughts or suggestions in the box below. Your input
          helps us improve!
        </Typography>
        <TextField
          label="Type here.."
          variant="outlined"
          value={feedback}
          onChange={e => handleFeedbackChange(e)}
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 2 }}
          required
          helperText={`${feedback.length}/1000`}
          error={feedback.length > 1000}
        />
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          fullWidth
          sx={{ mb: 2 }}
          error={!isEmailValid}
          helperText={!isEmailValid ? 'Please enter a valid email address' : ''}
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          fullWidth
          sx={{ mb: 2 }}
          error={!isPhoneNumberValid}
          helperText={
            !isPhoneNumberValid && phoneNumber !== ''
              ? 'Phone number must be a valid Indian number'
              : ''
          }
        />

        <Button
          variant="contained"
          fullWidth
          startIcon={<NearMeOutlinedIcon />}
          onClick={handleSendFeedback}
          disabled={!isButtonEnabled}
          sx={{
            backgroundColor: isButtonEnabled ? '#A0442F' : '#d3d3d3',
            color: isButtonEnabled ? '#fff' : '#a9a9a9',
            textTransform: 'none',
            borderRadius: 2,
            '&:hover': {
              backgroundColor: isButtonEnabled ? '#6D2312' : '#d3d3d3',
            },
          }}
        >
          Send Feedback
        </Button>
      </Box>
    </Container>
  );

  const pages = {
    main: renderMainPage(),
    faqs: renderFAQsPage(),
    feedback: renderFeedbackPage(),
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {pages[currentPage]}
    </Box>
  );
};

export default HelpSection;
