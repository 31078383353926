import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { Box, Typography, Button, Modal } from '@mui/material';
import AlbumThumbnail from './AlbumThumbnail';
import { InstanceVersionDetails } from '../types';
import 'react-image-gallery/styles/css/image-gallery.css';
import { getScannedImages } from '../api/scanneduploads';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { useQuery } from '@tanstack/react-query';
interface Album {
  name: string;
  images: { original: string; thumbnail: string }[];
  version: number;
}

const AlbumGallery: React.FC<{
  instanceVersionDetails: InstanceVersionDetails;
}> = ({ instanceVersionDetails }) => {
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [currentIndex, setCurrentIndex] = useState(0);
  const authenticatedApiHook = useAuthenticatedRequest();
  const [totalImageCount, setTotalImageCount] = useState<number>(0);

  const { data: scannedImages, isFetching } = useQuery({
    queryKey: [
      'scanned-images',
      selectedAlbum?.version,
      offset,
      instanceVersionDetails.instance_id,
    ],
    queryFn: () => {
      if (selectedAlbum) {
        return getScannedImages(
          authenticatedApiHook,
          instanceVersionDetails.instance_id,
          selectedAlbum.version,
          offset
        );
      }
      return null;
    },
    staleTime: 60 * 1000,
    enabled: !!selectedAlbum,
  });

  useEffect(() => {
    if (instanceVersionDetails) {
      const processedAlbums = instanceVersionDetails.versions
        .filter(version => version.scanned_pages.length > 0)
        .map(version => ({
          name: `${version.library.name} - Version ${version.version_id}`,
          images: version.scanned_pages.map(page => ({
            original: page.presigned_url,
            thumbnail: page.presigned_url, // Same image for thumbnails
          })),
          version: version.version_id,
        }));
      setAlbums(processedAlbums);
    }
  }, [instanceVersionDetails]);

  useEffect(() => {
    if (!isFetching && scannedImages) {
      const updatedImages = scannedImages.results.map(
        (image: { presigned_url: string }) => ({
          original: image.presigned_url,
          thumbnail: image.presigned_url,
        })
      );
      if (offset === 0) {
        setSelectedAlbum(prevAlbum => ({
          ...prevAlbum!,
          images: updatedImages,
        }));
      } else {
        setSelectedAlbum(prevAlbum => ({
          ...prevAlbum,
          images: [...(prevAlbum?.images || []), ...updatedImages],
          name: prevAlbum?.name || '',
          version: prevAlbum?.version || 0,
        }));
      }

      setTotalImageCount(scannedImages.count);
    }
  }, [scannedImages, isFetching]);

  const handleAlbumClick = async (album: Album) => {
    setSelectedAlbum(album);
    setOffset(0);
  };

  const loadMoreImages = async (currentIndex: number) => {
    const imagesLength = selectedAlbum?.images?.length ?? 0;

    // Don't load more images if we're already loading or if we've loaded all images
    if (isFetching || imagesLength >= totalImageCount) return;

    // Load more images only when the user is nearing the end of the gallery
    if (imagesLength > 0 && currentIndex >= imagesLength - 4) {
      setOffset(prevOffset => prevOffset + limit);
    }
  };

  const handleGalleryClose = () => {
    setSelectedAlbum(null);
    setCurrentIndex(0);
    setOffset(0);
    setTotalImageCount(0);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          marginTop: 2,
        }}
      >
        {albums.map((album, index) => (
          <AlbumThumbnail
            key={index}
            name={album.name}
            images={album.images.map(img => img.original)}
            onClick={() => handleAlbumClick(album)}
          />
        ))}
      </Box>
      <Modal
        open={selectedAlbum !== null}
        onClose={handleGalleryClose}
        aria-labelledby="image-gallery-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {selectedAlbum && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                {selectedAlbum.name}
              </Typography>
              <ImageGallery
                items={selectedAlbum.images}
                useBrowserFullscreen={true}
                showFullscreenButton={true}
                showPlayButton={false}
                showNav={true}
                showThumbnails={true}
                // showIndex={true}
                startIndex={currentIndex}
                lazyLoad={true}
                onSlide={(index: number) => {
                  setCurrentIndex(index);
                  loadMoreImages(index);
                }}
                additionalClass="image-gallery"
                renderCustomControls={() => {
                  return (
                    (totalImageCount ?? 0) > 0 && (
                      <div className="image-gallery-index-control">
                        <Typography variant="body2">
                          {`${currentIndex + 1} / ${totalImageCount}`}{' '}
                          {/* Custom format */}
                        </Typography>
                      </div>
                    )
                  );
                }}
              />
              <Button
                variant="contained"
                onClick={handleGalleryClose}
                sx={{ marginTop: 2 }}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AlbumGallery;
