import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useItems } from '../hooks/useItems';
import logo from '../assets/img/toplogo.png';
import cover from '../assets/img/homesearch.png';
import noitem from '../assets/img/noitem.png';
import LibraryTabs from '../components/LibraryTabs';
import CataloguedItems from '../components/CataloguedItems';
import ItemGrid from '../components/ItemGrid';
import ItemList from '../components/ItemList';
import NoItems from '../components/NoItems';
import LoadMoreButton from '../components/LoadMoreButton';
import FabButton from '../components/FabButton';
import HomeHeader from '../components/HomeHeader';
import HomeCover from '../components/HomeCover';
import styled from 'styled-components';
import { Instance, LibraryNameInstance, HomeFilters } from '../types';
import { LibraryType } from '../utils/enum';

const Home: React.FC = () => {
  const [filters, setFilters] = useState<HomeFilters>(() => {
    const storedFilters = localStorage.getItem('selectedFilters');
    if (storedFilters) {
      return JSON.parse(storedFilters);
    } else {
      return {
        itemType: [],
        source: [],
        scanning: [],
        restrictedItems: null,
        valuableItems: null,
      };
    }
  });
  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [libraryType, setLibraryType] = useState<LibraryType>(
    LibraryType.MY_LIBRARY
  );
  const [isGridView, setIsGridView] = useState(true);
  const [bookData, setBookData] = useState<(LibraryNameInstance | Instance)[]>(
    []
  );
  const [key, setKey] = useState<number>(0);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { items, totalBookCount, loading, totalInstanceCount } = useItems(
    search,
    offset,
    libraryType,
    filters
  );
  useEffect(() => {
    if (items.length > 0) {
      setKey(prevKey => prevKey + 1);
    }
    setBookData(items);
  }, [items]);

  useEffect(() => {
    //const searchParams = new URLSearchParams(window.location.search);
    const allLibrary = searchParams.get('allLibrary') === 'true';
    if (allLibrary) {
      setLibraryType(LibraryType.ALL_LIBRARIES);
    }
  }, []);

  useEffect(() => {
    if (search !== '') {
      setOffset(0);
    }
  }, [search]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const newUrl =
      libraryType === LibraryType.ALL_LIBRARIES
        ? `${currentUrl}?allLibrary=true`
        : currentUrl;
    window.history.replaceState({}, '', newUrl);
    setOffset(0);
  }, [libraryType]);

  useEffect(() => {
    localStorage.setItem('selectedFilters', JSON.stringify(filters));
  }, [filters]);

  const handleToggleView = () => {
    setIsGridView(prevIsGridView => !prevIsGridView);
  };

  const onLoadMore = () => setOffset(prev => prev + 10);

  return (
    <StyledContainer disableGutters>
      <HomeHeader t={t} logo={logo} />
      <HomeCover cover={cover} search={search} setSearch={setSearch} t={t} />
      <LibraryTabs setValue={setLibraryType} value={libraryType} />
      <FabButton />
      <CataloguedItems
        t={t}
        items={bookData}
        totalBookCount={totalBookCount}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        isGridView={isGridView}
        handleToggleView={handleToggleView}
        libraryType={libraryType}
      />
      <NoItems
        t={t}
        items={bookData}
        loading={loading}
        search={search}
        noitem={noitem}
        filter="" // pass props here
      />
      {isGridView ? (
        <ItemGrid key={key} items={bookData} libraryType={libraryType} />
      ) : (
        <ItemList key={key} items={bookData} libraryType={libraryType} />
      )}
      <LoadMoreButton
        items={bookData}
        totalInstanceCount={totalInstanceCount}
        totalBookCount={totalBookCount}
        loading={loading}
        onLoadMore={onLoadMore}
        search="" //pass props here
      />
    </StyledContainer>
  );
};

export default Home;

const StyledContainer = styled(Container)`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 64px;
`;
