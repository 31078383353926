import UTIF from 'utif';

export async function convertTIFFToPNG(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      try {
        const tiffBuffer = new Uint8Array(reader.result as ArrayBuffer);

        // Decode the TIFF
        const ifds = UTIF.decode(tiffBuffer);
        UTIF.decodeImage(tiffBuffer, ifds[0]);

        const rgba = UTIF.toRGBA8(ifds[0]);

        // Create a canvas to render the image
        const width = ifds[0].width;
        const height = ifds[0].height;
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);
        imageData.data.set(rgba);
        ctx.putImageData(imageData, 0, 0);

        // Extract the original file name without extension
        const originalName = file.name.replace(/\.[^/.]+$/, ''); // Remove the original extension

        // Convert the canvas to a PNG Blob
        canvas.toBlob(blob => {
          if (blob) {
            const pngFile = new File([blob], `${originalName}.png`, {
              type: 'image/png',
            });
            resolve(pngFile);
          } else {
            reject(new Error('Failed to create PNG Blob'));
          }
        }, 'image/png');
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}
