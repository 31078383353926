export const scanningStatusMap = {
  DO_NOT_SCAN: 'Do not scan',
  CANNOT_SCAN: 'Cannot scan',
  TO_BE_SCANNED: 'To be scanned',
  SCANNING_IN_PROGRESS: 'Scanning in progress',
  ALREADY_SCANNED: 'Already scanned',
  TO_BE_RE_SCANNED: 'To be re-scanned',
};

export const itemTypeMap = {
  BOOK: 'Book',
  MANUSCRIPT: 'Manuscript',
  WOODBLOCK: 'Woodblock',
  MODERN_PUBLICATION: 'Modern Publication',
};

export const ITEM_TYPE_OPTIONS = ['Book', 'Manuscript', 'Woodblock', 'Modern Publication'];
export const SCANNING_STATUS_OPTIONS = ['Do not scan','Cannot scan','To be scanned','Scanning in progress','Already scanned', 'To be re-scanned',]
export const SOURCE_OPTIONS = ['Borrowed', 'Donated', 'Purchased'];
export const OTHERS_OPTIONS = ['Valuable Item', 'Restricted Item'];