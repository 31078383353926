import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import {
  Box,
  Typography,
  Paper,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  CloudUpload,
  Image as ImageIcon,
  KeyboardArrowLeft,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getInstance } from '../api/items';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import {
  addScannedPage,
  getPresignedUrl,
  getScannedVersionDetails,
  uploadToBucket,
} from '../api/scanneduploads';
import { useSnackbar } from '../components/SnackbarProvider';
import { convertTIFFToPNG } from '../utils/compression';

interface FileWithProgress extends File {
  progress?: number;
}

const DropZone = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(8),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function ScannedUpload() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const instanceId = searchParams.get('instanceId');
  const versionId = searchParams.get('versionId');
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState<FileWithProgress[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [remainingFiles, setRemainingFiles] = useState(0);
  const [isProgressVisible, setProgressVisible] = useState(false);
  const { t } = useTranslation();
  const authenticatedApiHook = useAuthenticatedRequest();
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: instanceData } = useQuery({
    queryKey: ['instance', instanceId],
    queryFn: () => (instanceId ? getInstance(instanceId) : null),
    staleTime: 60 * 1000,
  });

  const { data: versionData } = useQuery({
    queryKey: ['scanned-version', instanceId, versionId],
    queryFn: () =>
      instanceId && versionId
        ? getScannedVersionDetails(authenticatedApiHook, instanceId, versionId)
        : null,
    staleTime: 60 * 1000,
  });

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const compressAndUploadFile = async (file: File) => {
    if (!isFileTypeValid(file)) {
      showMessage(
        'Unsupported file type. Only .tiff and .tif files are allowed.',
        'error',
        4000
      );
      return;
    }

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const pngFile = await convertTIFFToPNG(file);
      const compressedFile = await imageCompression(pngFile, options);
      // If no instanceId or versionId, exit the function
      if (!instanceId || !versionId) return;

      // Get the presigned URL for uploading the file
      const presignedUrl = await getPresignedUrl(
        authenticatedApiHook,
        instanceId,
        versionId,
        compressedFile.name
      );

      // Upload the compressed file to the presigned URL
      const uploadResult = await uploadToBucket(
        presignedUrl.url,
        compressedFile
      );
      const url = new URL(presignedUrl.url);
      const bucketPath = decodeURIComponent(url.pathname.slice(1));

      // If the upload was successful, add the scanned page with the URL returned
      if (uploadResult && uploadResult.success) {
        await addScannedPage(
          authenticatedApiHook,
          versionData.id,
          bucketPath,
          compressedFile.name
        );
        queryClient.invalidateQueries({ queryKey: ['scanned-version'] });
        queryClient.invalidateQueries({
          queryKey: ['scanned-images', instanceId, 0, versionId],
        });
        return true;
      } else {
        showMessage('Failed to Upload to bucket', 'error', 4000);
        return false;
      }
    } catch (error) {
      showMessage(`Error uploading file:${error}`, 'error', 4000);
      return false;
    }
  };

  const isFileTypeValid = (
    file: File,
    validExtensions: string[] = ['.tiff', '.tif']
  ): boolean => {
    const fileExtension = file.name
      .slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
    setRemainingFiles(droppedFiles.length);
    setProgressVisible(true);
    const uploadResults = [];
    let successfulUploads = 0;

    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      if (!isFileTypeValid(file)) {
        showMessage(
          'Unsupported file type. Only .tiff and .tif files are allowed.',
          'error',
          4000
        );
        return;
      }
      try {
        const isSuccess = await compressAndUploadFile(file);
        if (isSuccess) {
          successfulUploads += 1;
          setRemainingFiles(prev => prev - 1);
        } else {
          showMessage(`Failed to upload file: ${file.name}`, 'error', 4000);
        }
        setUploadProgress((successfulUploads / droppedFiles.length) * 100);
        uploadResults.push(isSuccess);
      } catch (error) {
        showMessage(`Error processing file: ${error}`, 'error', 4000);
        uploadResults.push(false);
      }
    }
    // After all files are processed, you can handle the overall result
    if (uploadResults.every(result => result)) {
      showMessage('All files uploaded successfully', 'success', 4000);
    } else {
      showMessage('Some files failed to upload', 'error', 4000);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      setFiles(selectedFiles);
      setRemainingFiles(selectedFiles.length);
      setProgressVisible(true);

      const uploadResults = [];
      let successfulUploads = 0;

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (!isFileTypeValid(file)) {
          showMessage(
            'Unsupported file type. Only .tiff and .tif files are allowed.',
            'error',
            4000
          );
          return;
        }
        const isSuccess = await compressAndUploadFile(file);
        if (isSuccess) {
          successfulUploads += 1;
          setRemainingFiles(prev => prev - 1);
        } else {
          showMessage(`Failed to upload file: ${file.name}`, 'error', 4000);
        }
        setUploadProgress((successfulUploads / selectedFiles.length) * 100);
        uploadResults.push(isSuccess);
      }
      if (uploadResults.every(result => result)) {
        showMessage('All files uploaded successfully', 'success', 4000);
      } else {
        showMessage('Some files failed to upload', 'error', 4000);
      }
      e.target.value = '';
    }
  };

  // const handleCloseProgressBox = () => {
  //   setProgressVisible(false);
  // };

  return (
    <Container
      disableGutters
      sx={{ height: '100vh', overflowY: 'scroll', pb: 8 }}
    >
      {/* Header Box */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        p={'16px'}
        bgcolor={'white'}
        width={'100vw'}
        maxWidth={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <IconButton onClick={() => navigate('/landing')} style={{ padding: 0 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
          {t('Upload Scanned Pages')}
        </Typography>
        <Box width={'30px'}></Box>
      </Box>
      <Box maxWidth="lg" mx="auto" p={6}>
        <Box mb={8}>
          <Typography variant="h4" component="h1" gutterBottom>
            Upload Scanned Images
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={4}>
            Image uploaded here will be stored for all libraries and viewed by
            all users
          </Typography>

          <Paper
            elevation={1}
            sx={{ p: 4, display: 'flex', alignItems: 'flex-start', gap: 4 }}
          >
            <img
              src={instanceData?.front_cover}
              alt={instanceData?.title}
              width={80}
              height={120}
              style={{ objectFit: 'cover', borderRadius: '4px' }}
            />
            <Box>
              <Typography variant="h6">{instanceData?.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {instanceData?.author}
              </Typography>
              <Typography variant="body2">
                Scanned Version: {versionId}
              </Typography>
              <Typography variant="body2">
                Total Pages: {versionData?.total_page_count}
              </Typography>
              <Typography variant="body2">
                Pages uploaded: {versionData?.pages_uploaded}
              </Typography>
            </Box>
          </Paper>
        </Box>

        <Box mb={8}>
          <Typography variant="h5" component="h2" gutterBottom>
            Add New Image
          </Typography>
          <DropZone
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            sx={{
              backgroundColor: dragActive ? 'action.hover' : 'background.paper',
            }}
          >
            <input
              type="file"
              id="file-upload"
              multiple
              onChange={handleFileChange}
              accept=".tiff,.tif"
              style={{ display: 'none' }}
            />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Box p={2} bgcolor="primary.light" borderRadius="50%">
                <CloudUpload fontSize="large" color="primary" />
              </Box>
              <Typography variant="h6" color="primary">
                Drag & Drop
              </Typography>
              <Typography variant="body1" color="primary">
                your files here
              </Typography>
              <Typography variant="body2" color="text.secondary">
                or
              </Typography>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                color="primary"
              >
                Browse
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept=".tiff,.tif"
                />
              </Button>
            </Box>
          </DropZone>
        </Box>

        {files.length > 0 && isProgressVisible && (
          <Paper
            elevation={3}
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 24,
              width: 320,
              p: 3,
              maxHeight: 400,
              overflow: 'auto',
            }}
          >
            <Box mb={2}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="body2" fontWeight="medium">
                  {Math.round(uploadProgress)}% complete
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {remainingFiles} files remaining
                </Typography>
              </Box>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
            <List dense>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <ImageIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))}
            </List>
            {/* <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleCloseProgressBox}
            sx={{ mt: 2 }}
          >
            Close
          </Button> */}
          </Paper>
        )}
      </Box>
    </Container>
  );
}
