import React from 'react';
import { Box, Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

interface AlbumThumbnailProps {
  images: string[];
  name: string;
  onClick: () => void;
}

const AlbumThumbnail: React.FC<AlbumThumbnailProps> = ({
  images,
  name,
  onClick,
}) => {
  return (
    <Box
      sx={{
        width: 49,
        height: 49,
        borderRadius: 1,
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
          '& .albumName': {
            opacity: 1,
          },
        },
      }}
      onClick={onClick}
    >
      {images.length > 0 ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gap: '1px',
            width: '100%',
            height: '100%',
          }}
        >
          {images.slice(0, 4).map((src, index) => (
            <Box
              key={index}
              sx={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <img
                src={src}
                alt={`Album ${name} image ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'action.selected',
          }}
        >
          <FolderIcon sx={{ fontSize: 24, color: 'text.secondary' }} />
        </Box>
      )}
      <Box
        className="albumName"
        sx={{
          position: 'absolute',
          inset: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0,
          transition: 'opacity 0.2s',
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: 'white', fontWeight: 'bold', fontSize: '8px' }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default AlbumThumbnail;
