import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Container,
  IconButton,
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { createScannedVersion } from '../api/scanneduploads';
import { useQuery } from '@tanstack/react-query';
import { getLibraryList } from '../api/library';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { useSnackbar } from '../components/SnackbarProvider';

export default function AddScannedVersion() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const instanceId = searchParams.get('instanceId');
  const [library, setLibrary] = useState<string>('');
  const [pageCount, setPageCount] = useState<string>('');
  const authenticatedApiHook = useAuthenticatedRequest();
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();

  const { data: libraryList } = useQuery({
    queryKey: ['libraryList'],
    queryFn: () => getLibraryList(authenticatedApiHook),
    staleTime: 60 * 1000,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!instanceId) return;
    if (!library || !pageCount) {
      showMessage(
        t(
          'Please select a library and enter the total page count before submitting.'
        ),
        'error',
        4000
      );
      return;
    }

    try {
      const { version_id } = await createScannedVersion(
        authenticatedApiHook,
        instanceId,
        library,
        parseInt(pageCount)
      );
      navigate(
        `/scanned-upload?instanceId=${instanceId}&versionId=${version_id}`
      );
    } catch (error) {
      console.error('Error creating version:', error);
      showMessage(
        t(
          'An error occurred while creating the scanned version. Please try again.'
        ),
        'error',
        4000
      );
    }
  };

  return (
    <Container
      disableGutters
      sx={{ height: '100vh', overflowY: 'scroll', pb: 8 }}
    >
      <Box
        display="flex"
        flexDirection="row"
        p="16px"
        bgcolor="white"
        width="100vw"
        maxWidth="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton onClick={() => navigate('/landing')} style={{ padding: 0 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize="16px" fontWeight={500}>
          {t('Add New Scanned Version')}
        </Typography>
        <Box width="30px"></Box>
      </Box>
      <Box maxWidth="lg" mx="auto" p={6}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <form onSubmit={handleSubmit}>
            <Box mb={4}>
              <FormControl fullWidth>
                <InputLabel id="library-label">Library</InputLabel>
                <Select
                  labelId="library-label"
                  value={library}
                  onChange={e => setLibrary(e.target.value as string)}
                  label="Library"
                >
                  {libraryList?.map(library => (
                    <MenuItem key={library.id} value={library.id}>
                      {library.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={4}>
              <TextField
                fullWidth
                label="Total Page Count"
                type="number"
                value={pageCount}
                onChange={e => setPageCount(e.target.value)}
              />
            </Box>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Create Version
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
