import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ActivateIcon from '@mui/icons-material/CheckCircle';
import DeactivateIcon from '@mui/icons-material/RemoveCircle';
import ChangePasswordIcon from '@mui/icons-material/Lock';
import { changeUserPassword, toggleActivation } from '../api/items';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { useSnackbar } from './SnackbarProvider';
/* eslint-disable @typescript-eslint/no-unused-vars */

interface UserProfileActionsProps {
  userId?: string | null;
  userName: string;
  isActive: boolean;
}

const UserProfileActions: React.FC<UserProfileActionsProps> = ({
  userId,
  userName,
  isActive,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeactivated, setIsDeactivated] = useState(!isActive);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    'deactivate' | 'changePassword' | null
  >(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const authenticatedApiHook = useAuthenticatedRequest();
  const { showMessage } = useSnackbar();

  const getInitials = (name: string) => {
    const nameParts = name.split(' ');
    return nameParts
      .map(part => part[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };

  // Open and close menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Open specific dialog
  const handleDialogOpen = (type: 'deactivate' | 'changePassword') => {
    setDialogType(type);
    setIsDialogOpen(true);
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setDialogType(null);
    setNewPassword('');
    setConfirmPassword('');
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      showMessage('Passwords do not match!', 'error', 4000);
      return;
    }

    if (userId) {
      const response = await changeUserPassword(
        authenticatedApiHook,
        userId,
        newPassword,
        confirmPassword
      );
      if (response) handleDialogClose();
    }
  };

  const handleToggleActivation = async () => {
    try {
      if (userId) {
        if (isDeactivated) {
          // Activate the account
          const response = await toggleActivation(
            authenticatedApiHook,
            userId,
            'reactivate'
          );
          if (response) {
            setIsDeactivated(false);
            handleDialogClose();
            showMessage('User re-activated successfully!', 'success', 4000);
          }
        } else {
          // Deactivate the account
          const response = await toggleActivation(
            authenticatedApiHook,
            userId,
            'deactivate'
          );
          if (response) {
            setIsDeactivated(true);
            handleDialogClose();
            showMessage('User deactivated successfully!', 'success', 4000);
          }
        }
      }
    } catch (error) {
      showMessage('Error while toggling account status', 'error', 4000);
    }
  };

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="16px"
      border="1px solid #ddd"
      borderRadius="8px"
    >
      <IconButton 
        onClick={handleMenuOpen}
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
        }}
      >
        <MoreVertIcon />
      </IconButton>

    <Avatar sx={{ width: 121, height: 121, marginBottom: '8px',fontWeight: '400', fontSize: '47.45px', fontFamily: 'Philosopher' }}>
      {getInitials(userName)}
    </Avatar>
    <Typography variant="h6" fontWeight="600" textAlign="center">
      {userName}
    </Typography>
    {isDeactivated &&
      <Typography fontWeight="400" fontSize="14px" textAlign="center" color="#F95656">
        Deactivated
      </Typography>
    }

      {/* Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'left', 
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            maxWidth: 'calc(100vw - 16px)',
            overflowX: 'auto',
          },
        }}
      >
        {isDeactivated ? (
          <MenuItem onClick={handleToggleActivation}>
            <ActivateIcon style={{ marginRight: '8px', color: '#3183FF' }} />
            <span style={{ color: '#3183FF' }}>Activate Account</span>
          </MenuItem>
        ) : (
          <>
            <MenuItem onClick={() => handleDialogOpen('changePassword')}>
              <ChangePasswordIcon
                style={{ marginRight: '8px', color: '#4579FF' }}
              />
              <span style={{ color: 'blue' }}>Change Password</span>
            </MenuItem>
            <Divider sx={{ m: 2, width: '80%' }} />
            <MenuItem onClick={() => handleDialogOpen('deactivate')}>
              <DeactivateIcon style={{ marginRight: '8px', color: '#F95656' }} />
              <span style={{ color: 'red' }}>Deactivate Account</span>
            </MenuItem>
          </>
        )}
      </Menu>

      {/* Dialog for Changing Password */}
      <Dialog
        open={isDialogOpen && dialogType === 'changePassword'}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            margin: 0,
          },
        }}
      >
         <Box
            sx={{
            width: '37px',
            height: '4px',
            backgroundColor: '#494949',
            borderRadius: '2px',
            margin: '10px auto',
          }}
          >
        </Box>
        <DialogTitle>
          <Typography fontWeight="600" fontSize="20px" marginTop="8px">
            Create New Password
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ my: 2  }} variant="outlined"  fullWidth>
            <InputLabel htmlFor="outlined-adornment-newpassword">
              New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-newpassword"
              type={showPassword1 ? 'text' : 'password'}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword1} edge="end">
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              placeholder="Type new password here"
            />
          </FormControl>
          <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-confirmpassword">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirmpassword"
              type={showPassword2 ? 'text' : 'password'}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword2} edge="end">
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              placeholder="Re-type new password here"
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handlePasswordSubmit}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#6D2312',
              color: '#fff',
              width: '100%',
              borderRadius: '150px',
              '&:hover': { backgroundColor: '#8B0000' },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Deactivate Confirmation */}
      <Dialog
        open={isDialogOpen && dialogType === 'deactivate'}
        onClose={handleDialogClose}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            width: '328px',
            height: '351px'
          },
        }}
      >
        <DialogContent sx={{ textAlign: 'center', padding: '24px' }}>
          <Typography textAlign="center" color="#242424" fontWeight="600" fontSize="16px">
            Are you sure you want to deactivate this user?
          </Typography>

          <Avatar
            sx={{
              margin: '0 auto',
              width: '114px',
              height: '114px',
              backgroundColor: '#f9565617',
              color: '#6D2312',
              fontWeight: '400',
              fontSize: '20px',
              fontFamily: 'serif'
            }}
          >
            {userName}
          </Avatar>

          <Typography fontWeight="400" fontSize="14px" marginTop={2} color="#888888">
            This would prevent the user from logging into the app but will not
            delete items catalogued by them.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
          <Button onClick={handleDialogClose}>
          <Typography color="#113A82" fontWeight="600" fontSize="16px">
              Cancel
            </Typography>
            
          </Button>
          <Button
            onClick={() => {
              handleToggleActivation();
            }}
            sx={{ marginLeft: '8px' }}
          >
            <Typography color="#FF725A" fontWeight="600" fontSize="16px">
              Deactivate
            </Typography>
            
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserProfileActions;
