import {
  GET_MY_LIBRARY_ENDPOINT,
  LIBRARY_BOOKS_COUNT_ENDPOINT,
  LIBRARY_ENDPOINT,
  SET_MY_LIBRARY_ENDPOINT,
  DOWNLOAD_CSV_ENDPOINT,
  LIST_ALL_STATES_ENDPOINT,
  LIBRARY_LIST,
} from '../utils/api';

import { AuthenticatedApiHook, LibraryDetails, HomeFilters } from '../types';
import { authenticatedFetch, mapAndFilterFilters } from './utils';
import { itemTypeMap, scanningStatusMap } from '../utils/constants';

export async function getLibraryDetails(library_id?: string) {
  let url = `${GET_MY_LIBRARY_ENDPOINT}/`;

  const params = new URLSearchParams();
  if (library_id) {
    params.append('library_id', library_id);
  }
  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  const response = await authenticatedFetch(url);
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function getStates(authenticatedApiHook: AuthenticatedApiHook) {
  const url = `${LIST_ALL_STATES_ENDPOINT}/`;
  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  }
}

export async function getLibraryBookCount(
  authenticatedApiHook: AuthenticatedApiHook,
  item_type?: string,
  search?: string,
  onlyMyItems: boolean = false,
  archived?: boolean,
  all_library?: boolean,
  filters?: HomeFilters
) {
  let url = `${LIBRARY_BOOKS_COUNT_ENDPOINT}/`;
  const params = new URLSearchParams();
  if (all_library) {
    params.append('all_library', 'true');
  }
  if (onlyMyItems) {
    params.append('only_mine', 'true');
  }
  if (item_type) {
    params.append('item_type', item_type);
  }
  if (archived) {
    params.append('archived', 'true');
  }
  if (search) {
    params.append('search', search);
  }

  if (filters) {
    if (filters.itemType && filters.itemType.length > 0) {
      const mappedItemTypes = mapAndFilterFilters(
        filters.itemType,
        itemTypeMap
      );
      if (mappedItemTypes) {
        params.append('item_type', mappedItemTypes);
      }
    }
    if (filters.libraries && filters.libraries.length > 0) {
      const libraryIds = filters.libraries.map(library => library.id);
      params.append('libraries', libraryIds.join(','));
    }

    if (filters.scanning && filters.scanning.length > 0) {
      const mappedScanningStatuses = mapAndFilterFilters(
        filters.scanning,
        scanningStatusMap
      );
      if (mappedScanningStatuses) {
        params.append('scanning_status', mappedScanningStatuses);
      }
    }

    if (filters.source && filters.source.length > 0) {
      params.append('source', filters.source.join(','));
    }

    if (filters.valuableItems === 'yes') {
      params.append('valuable', 'yes');
    }

    if (filters.restrictedItems === 'yes') {
      params.append('restricted', 'yes');
    }
  }

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function setLibraryDetails(
  authenticatedApiHook: AuthenticatedApiHook,
  libraryDetails: LibraryDetails
) {
  const response = await authenticatedApiHook(
    `${SET_MY_LIBRARY_ENDPOINT}/`,
    'POST',
    'multipart/form-data',
    libraryDetails
  );
  if (response) return response.json();
}

export async function setLastAccessionNumber(
  libraryId: string,
  lastAccessionNumber: number
) {
  const response = await authenticatedFetch(
    `${LIBRARY_ENDPOINT}${libraryId}/`,
    { last_accession_number: lastAccessionNumber },
    'PATCH'
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function downloadLibraryDetails() {
  try {
    const response = await authenticatedFetch(DOWNLOAD_CSV_ENDPOINT);

    if (!response.ok) {
      throw new Error('Failed to fetch the CSV file');
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'library_items.csv';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
}

export async function getLibraryList(
  authenticatedApiHook: AuthenticatedApiHook
) {
  const url = `${LIBRARY_LIST}/`;
  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  }
}

export async function getLibrariesDetails(
  authenticatedApiHook: AuthenticatedApiHook
) {
  const response = await authenticatedApiHook(LIBRARY_ENDPOINT);
  if (response) {
    return await response.json();
  }
}
