import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Instance, ItemInstance, LibraryDetails } from '../types';
import { getInstance, getItems, getItem } from '../api/items';
import { getInstanceVersionsDetails } from '../api/scanneduploads';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import CopyComponent from '../components/CopyComponent';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import React from 'react';
import rect from '../assets/img/Rect.png';
import { scanningStatusMap } from '../utils/constants';
import { toTitleCase } from '../utils/func';
import { useTranslation } from 'react-i18next';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from '@emotion/styled';
import AlbumGallery from '../components/AlbumGallery';
import AddIcon from '@mui/icons-material/Add';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useQuery } from '@tanstack/react-query';

const StyledLoadMore = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

import { useSearchParams } from 'react-router-dom';
import InstanceComments from '../components/InstanceComments';
import { getLibraryDetails } from '../api/library';

export default function ViewInstance() {
  const navigate = useNavigate();
  const { instanceId } = useParams();
  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('itemId');
  const libraryId = searchParams.get('libraryId') || null;
  const archivedParam = searchParams.get('archived');
  const archived = archivedParam === 'true';
  const allLibrary = searchParams.get('allLibrary') === 'true';
  const onlyMyItemsParam = searchParams.get('onlyMyItems');
  const onlyMyItems = onlyMyItemsParam === 'true';
  const userId = searchParams.get('userId');
  const [value, setValue] = React.useState(allLibrary ? '2' : '1');
  const [copiesData, setCopiesData] = useState<ItemInstance[]>([]);
  const [totalCopyCount, setTotalCopyCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [libraryDetails, setLibraryDetails] = useState<LibraryDetails>({
    name: '',
    unique_id: '',
    alternate_name: '',
    address: '',
    geo_location: '',
    logo: null,
  });
  const [libraryLogo, setLibraryLogo] = useState<string | null>(null);
  const [titleExpand, setTitleExpand] = useState(false);
  const authenticatedApiHook = useAuthenticatedRequest();

  const handleToggleExpand = () => {
    setTitleExpand(!titleExpand);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [instance, setInstance] = useState<Instance>({
    title: '',
    isbn: '',
    author: '',
    front_cover: '',
    back_cover: '',
    random_page: '',
    notes: '',
    publisher: '',
    published_year: '',
    place_of_publication: '',
    page_count: '',
    item_type: '',
    scanning_status: '',
  });
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [updateCopies, setUpdateCopies] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddNewVersion = () => {
    navigate(`/add-scanned-version?instanceId=${instanceId}`);
    handleClose();
  };

  const handleSelectVersion = (versionId: number) => {
    navigate(`/scanned-upload?instanceId=${instanceId}&versionId=${versionId}`);
    handleClose();
  };

  const { data: instanceVersionDetails } = useQuery({
    queryKey: ['instance-version-details', value, instanceId],
    queryFn: () => {
      if (value === '2' && instanceId) {
        return getInstanceVersionsDetails(authenticatedApiHook, instanceId);
      }
      return null;
    },
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    const loadData = async () => {
      if (updateCopies) {
        setUpdateCopies(false);
        return;
      }
      if (instanceId) {
        setLoading(true);
        setInstance(await getInstance(instanceId));
        if (itemId) {
          const item = await getItem(itemId, archived);
          if (item) {
            setCopiesData([item]);
            setTotalCopyCount(1);
          }
        } else {
          const { items, count } = await getItems(
            undefined,
            instanceId,
            false,
            offset
          );
          setTotalCopyCount(count);
          setCopiesData(prevItems =>
            offset === 0 ? items : [...prevItems, ...items]
          );
        }
        setLoading(false);
      }
    };

    loadData();
  }, [instanceId, itemId, archived, offset]);

  useEffect(() => {
    if (libraryId) {
      (async () => {
        try {
          const details = await getLibraryDetails(libraryId);
          setLibraryDetails(details);
          setLibraryLogo(details?.logo);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [libraryId]);

  useEffect(() => {
    const handleBackNavigation = () => {
      navigate('/landing');
    };

    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);

  const handleUpdateCopies = async () => {
    const { items, count } = await getItems(undefined, instanceId);
    setTotalCopyCount(count);
    setCopiesData(items);
    setUpdateCopies(true);
    setOffset(0);
  };

  if (!instance) {
    return <div>Instance Not found</div>;
  }

  const onLoadMore = () => {
    if (copiesData.length < totalCopyCount) {
      setOffset(prevOffset => prevOffset + 10);
    }
  };

  return (
    <Container
      disableGutters
      sx={{ height: '100vh', overflowY: 'scroll', pb: 8 }}
    >
      {/* Header Box */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        p={'16px'}
        bgcolor={'white'}
        width={'100vw'}
        maxWidth={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'fixed'}
        top={0}
        left={0}
        zIndex={900}
      >
        <IconButton
          onClick={() => {
            if (archived) {
              navigate('/my-items', { state: { isArchived: true } });
            } else if (onlyMyItems) {
              navigate('/my-items');
            } else if (allLibrary) {
              navigate('/landing?allLibrary=true');
            } else {
              const targetPath = userId
                ? `/my-items?user_id=${userId}`
                : '/landing';
              navigate(targetPath);
            }
          }}
          style={{ padding: 0 }}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
          {t('Item')}
        </Typography>
        <Box width={'30px'}></Box>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${rect})`,
          marginTop: '50px',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: 'auto',
          backgroundSize: 'contain',
          mb: '16px',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
          padding: '16px',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleToggleExpand}
      >
        <Typography
          fontSize={'24px'}
          color={'#FFFFFF'}
          fontWeight={700}
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: titleExpand ? 'unset' : 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '1.5',
          }}
        >
          {instance?.title}
          {!titleExpand && instance?.title.length > 200 && (
            <Box
              component="span"
              sx={{
                color: '#FFFFFF',
                fontWeight: 700,
                ml: 1,
                cursor: 'pointer',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '2px 6px',
                borderRadius: '4px',
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              ...
            </Box>
          )}
        </Typography>
      </Box>
      <Typography
        fontSize={'16px'}
        color={'#908056'}
        fontWeight={400}
        px={'16px'}
        pb={1}
      >
        {instance?.author} {instance?.published_year}
      </Typography>
      {/* <Typography fontSize={'16px'} color={'#242424'} fontWeight={400} px={'16px'} pb={1}>
        {instance?.publisher}
      </Typography>
      <Typography fontSize={'16px'} color={'#242424'} fontWeight={400} px={'16px'} pb={1}>
        Place of Publication - {instance?.place_of_publication}
      </Typography> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
        my={2}
      >
        <img
          src={instance?.front_cover}
          alt="Front Cover"
          style={{
            width: '100px',
            height: '150px',
            borderRadius: '8px',
            borderColor: '#908056',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
          onClick={() => {
            setPreviewImage(instance?.front_cover || '');
          }}
        />
        {instance?.random_page && (
          <img
            src={instance?.random_page}
            alt="Back Cover"
            style={{
              width: '100px',
              height: '150px',
              borderRadius: '8px',
              borderColor: '#908056',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            onClick={() => {
              setPreviewImage(instance?.random_page || '');
            }}
          />
        )}
        {instance?.back_cover && (
          <img
            src={instance?.back_cover}
            alt="Random Page"
            style={{
              width: '100px',
              height: '150px',
              borderRadius: '8px',
              borderColor: '#908056',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            onClick={() => {
              setPreviewImage(instance?.back_cover || '');
            }}
          />
        )}
      </Box>

      {/* <Divider sx={{m: '16px'}} /> */}
      {!allLibrary && (
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab
            value="1"
            label={
              <Box display={'flex'} alignItems={'center'}>
                {t('Copy Details')}
                <Box
                  padding={'4px 12px'}
                  borderRadius={'22px'}
                  bgcolor={'#D1DBE9'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  ml={'4px'}
                >
                  {totalCopyCount}
                </Box>
              </Box>
            }
            wrapped
          ></Tab>
          <Tab value="2" label={t('More Info')} wrapped></Tab>
        </Tabs>
      )}
      {value == '2' && (
        <Box p={'18px'} pb={3}>
          {libraryId !== null && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              sx={{ paddingBottom: '20px' }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between" // Distribute space between text and logo
                paddingTop={'20px'}
                paddingBottom={'12px'}
              >
                <Box>
                  <Typography fontSize={'14px'} fontWeight={400}>
                    {t('Library Name')}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={400}
                    sx={{ opacity: 0.7 }}
                  >
                    {libraryDetails?.name}
                  </Typography>
                </Box>
                {libraryLogo && (
                  <Box
                    sx={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      marginLeft: '12px',
                      background: '#FFFFFF',
                      border: '1px solid #6D2312',
                    }}
                  >
                    <img
                      src={libraryLogo}
                      alt="Library Logo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Address')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{
                    width: '100%',
                    opacity: 0.7,
                  }}
                >
                  {libraryDetails?.address}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            display={'flex'}
            sx={{ borderBottom: '1px solid #E6E2DD', paddingBottom: '20px' }}
          >
            <Box width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}>
              <Typography fontSize={'14px'} fontWeight={400}>
                {t('Item Type')}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                sx={{ opacity: 0.7 }}
              >
                {t(toTitleCase(instance?.item_type?.replace(/_/g, ' ')))}
              </Typography>
            </Box>
            <Box width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}>
              <Typography fontSize={'14px'} fontWeight={400}>
                {t('ISBN')}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                sx={{ opacity: 0.7 }}
              >
                {instance?.isbn}
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ borderBottom: '1px solid #E6E2DD', paddingBottom: '20px' }}
          >
            <Box
              width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
              paddingTop={'20px'}
              paddingBottom={'12px'}
            >
              <Typography fontSize={'14px'} fontWeight={400}>
                {t('Collection Name')}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                sx={{ opacity: 0.7 }}
                padding={'0'}
              >
                {instance?.collection_name}
              </Typography>
            </Box>
            <Box width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}>
              <Typography fontSize={'14px'} fontWeight={400}>
                {t('Volume Number')}
              </Typography>
              <Typography
                fontSize={'14px'}
                fontWeight={400}
                sx={{ opacity: 0.7 }}
              >
                {instance?.volume_number}
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ borderBottom: '1px solid #E6E2DD', paddingBottom: '20px' }}
          >
            <Box display={'flex'}>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                paddingTop={'20px'}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Publisher')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                  // maxWidth={'154px'}
                  textOverflow={'ellipsis'}
                  paddingBottom={'12px'}
                >
                  {instance?.publisher}
                </Typography>
              </Box>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                paddingTop={'20px'}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Place of publication')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                  // maxWidth={'154px'}
                  textOverflow={'ellipsis'}
                >
                  {instance?.place_of_publication}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'}>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                paddingBottom={'12px'}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Edition')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                  // maxWidth={'154px'}
                  textOverflow={'ellipsis'}
                >
                  {instance?.edition}
                </Typography>
              </Box>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Number of Pages')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                >
                  {instance.page_count}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'}>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Year')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                >
                  {instance?.published_year}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ borderBottom: '1px solid #E6E2DD', paddingBottom: '20px' }}
          >
            <Box display={'flex'}>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                paddingTop={'20px'}
                paddingBottom={'12px'}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Review Item')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                >
                  {instance?.review_item ? t('Yes') : t('No')}
                </Typography>
              </Box>
              <Box
                width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                paddingTop={'20px'}
              >
                <Typography fontSize={'14px'} fontWeight={400}>
                  {t('Restricted')}
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={400}
                  sx={{ opacity: 0.7 }}
                >
                  {instance?.restricted_access ? t('Yes') : t('No')}
                </Typography>
              </Box>
            </Box>
            <>
              <Box
                display={'flex'}
                justifyContent="space-between"
                paddingBottom={'20px'}
              >
                <Box
                  width={{ xs: '160px', sm: '180px', md: '200px', lg: '400px' }}
                >
                  <Typography fontSize={'14px'} fontWeight={400}>
                    {t('Scanning Status')}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={400}
                    sx={{ opacity: 0.7 }}
                  >
                    {t(
                      instance?.scanning_status &&
                        instance.scanning_status in scanningStatusMap
                        ? scanningStatusMap[
                            instance.scanning_status as keyof typeof scanningStatusMap
                          ]
                        : ''
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={handleClick}
                    startIcon={<AddIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{
                      color: '#5095FD',
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: 400,
                      letterSpacing: '3%',
                      lineHeight: '19.91px',
                    }}
                  >
                    {t('Add Images')}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      style: {
                        maxHeight: '200px',
                        overflowY: 'auto',
                        position: 'initial',
                      },
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleAddNewVersion}>
                      <AddIcon
                        fontSize="small"
                        sx={{ marginRight: 1, color: '#5095FD' }}
                      />
                      <Typography>{t('Add New Version')}</Typography>
                    </MenuItem>
                    {instanceVersionDetails &&
                      instanceVersionDetails.versions.map(version => (
                        <MenuItem
                          key={version.id}
                          onClick={() =>
                            handleSelectVersion(version.version_id)
                          }
                        >
                          <Typography>{`${version.library.name}-version-${version.version_id}`}</Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                </Box>
              </Box>
              {instanceVersionDetails && (
                <AlbumGallery instanceVersionDetails={instanceVersionDetails} />
              )}
            </>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ borderBottom: '1px solid #E6E2DD', paddingBottom: '20px' }}
          >
            <Box paddingTop={'20px'}>
              <Typography fontSize={'14px'} fontWeight={400}>
                {t('Additional comment')}
              </Typography>
            </Box>
            <Box mt={1} mb={2}>
              <Typography fontSize={'14px'} fontWeight={400}>
                {instance?.notes}
              </Typography>
            </Box>
            {instanceId && <InstanceComments instanceId={instanceId} />}
          </Box>
        </Box>
      )}

      {value == '1' && (
        <Box p={'16px'} pb={8}>
          {copiesData.map((copy, index) => (
            <CopyComponent
              key={copy.id || index}
              index={index}
              copy={{
                id: copy.id ? String(copy.id) : '',
                temporary_shelf_number: copy.temporary_shelf_number,
                accession_code: copy.accession_code,
                condition: copy.condition,
                temporary_accession_code: copy.temporary_accession_code,
                instance: instanceId ? instanceId : '',
                source: copy.source,
                archived: copy.archived,
              }}
              onUpdate={handleUpdateCopies}
              totalCopies={copiesData.length}
            />
          ))}
          {copiesData.length < totalCopyCount && (
            <StyledLoadMore>
              <Button onClick={onLoadMore} disabled={loading}>
                {loading ? `${t('Loading')}...` : t('Load More')}
              </Button>
            </StyledLoadMore>
          )}
        </Box>
      )}

      <Box
        position={'fixed'}
        bottom={'0'}
        left={'50%'}
        sx={{
          transform: 'translateX(-50%)',
          width: '100%',
          backgroundColor: 'white',
          zIndex: 'tooltip',
        }}
        p={'16px'}
      >
        {value == '1' && itemId == null && (
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              py: 2,
              borderRadius: 150,
              width: '100%',
            }}
            onClick={() => {
              if (!instanceId) return;
              setCopiesData(prev => [
                ...prev,
                {
                  temporary_shelf_number: '',
                  accession_code: '',
                  condition: '',
                  instance: instanceId,
                },
              ]);
              // setValue('2');
            }}
          >
            <AddBoxOutlinedIcon />
            <Typography
              fontSize={16}
              fontWeight={400}
              color="white"
              letterSpacing={'0.96px'}
              marginLeft={'8px'}
            >
              {t('Add Copy')}
            </Typography>
          </Button>
        )}
        {value == '2' && !allLibrary && (
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              py: 2,
              borderRadius: 150,
              width: '100%',
            }}
            onClick={() => {
              navigate(`/edit-item/${instanceId}?allLibrary=${allLibrary}`);
            }}
          >
            <EditOutlinedIcon sx={{ fontSize: '20px' }} />
            <Typography
              fontSize={16}
              fontWeight={400}
              color="white"
              letterSpacing={'0.96px'}
              marginLeft={'8px'}
            >
              {t('Edit Item')}
            </Typography>
          </Button>
        )}
      </Box>

      <Modal
        open={!!previewImage}
        onClose={() => setPreviewImage(null)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10000,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => setPreviewImage(null)}
            sx={{
              position: 'absolute',
              right: -2,
              top: -2,
              color: 'red',
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <img
            src={previewImage || instance.front_cover}
            alt="Full Size"
            style={{ maxWidth: '100%', maxHeight: '90vh' }}
          />
        </Box>
      </Modal>
    </Container>
  );
}
