import { Archive, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  // MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { addOrUpdateItem, setItemArchived } from '../api/items';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { EditItemData } from '../types';
import QRCodeScanner from './QRCodeScanner';
import { useSnackbar } from './SnackbarProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGenericMutation from '../hooks/MutationHook';
import AutoCompleteDropdown from './AutocompleteDropdown';
/* eslint-disable @typescript-eslint/no-explicit-any */

const CopyComponent: React.FC<{
  index: number;
  copy: EditItemData;
  onUpdate: () => void;
  totalCopies: number;
}> = ({ index, copy, onUpdate, totalCopies }) => {
  const [disable, setDisable] = useState(copy.id ? true : false);
  const [qrEnabled, setQREnabled] = useState(!disable);
  const { mutate } = useGenericMutation();
  const [copyState, setCopyState] = useState<EditItemData>({
    id: copy.id,
    temporary_shelf_number: copy.temporary_shelf_number,
    accession_code: copy.accession_code,
    condition: copy.condition,
    instance: copy.instance,
    temporary_accession_code: copy.temporary_accession_code || '',
    source: copy.source || '',
  });
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();

  const authenticatedApiHook = useAuthenticatedRequest();
  const navigate = useNavigate();

  const copyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (index == totalCopies - 1) {
      // @ts-expect-error: `copyRef.current` might be null during initial render, but we ensure it is defined before calling `scrollIntoView`.
      copyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [index]);

  useEffect(() => {
    setQREnabled(!disable);
  }, [disable]);

  const handleSaveCopy = async () => {
    setDisable(true);
    await addOrUpdateItem(authenticatedApiHook, {
      id: copyState.id,
      temporary_shelf_number: copyState.temporary_shelf_number,
      accession_code: copyState.accession_code,
      condition: copyState.condition,
      instance: copyState.instance,
      temporary_accession_code: copyState.temporary_accession_code,
      source: copyState.source,
    });
    onUpdate();
  };

  const handleChange = (name: string, value: any) => {
    if (name === 'accession_code') {
      if (/^\d*$/.test(value)) {
        setCopyState(prev => ({ ...prev, accession_code: value }));
      } else {
        showMessage('Only numerals are allowed', 'error', 4000);
        setCopyState(prev => ({ ...prev, accession_code: '' }));
        return;
      }
    }
    if (name === 'shelf_input_qr') {
      if (/^[a-zA-Z0-9\u0F00-\u0FFF]*$/.test(value)) {
        setCopyState(prev => ({ ...prev, temporary_shelf_number: value }));
      } else {
        showMessage('Only alphanumeric characters are allowed', 'error', 4000);
        setCopyState(prev => ({ ...prev, temporary_shelf_number: '' }));
        return;
      }
    }
    if (name === 'temporary_accession_code') {
      if (/^[a-zA-Z0-9\u0F00-\u0FFF\s]*$/.test(value)) {
        setCopyState(prev => ({ ...prev, temporary_accession_code: value }));
      } else {
        showMessage('Only alphanumeric characters are allowed', 'error', 4000);
        setCopyState(prev => ({ ...prev, temporary_accession_code: '' }));
        return;
      }
    } else {
      setCopyState(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleArchive = async () => {
    setDisable(true);
    if (copyState.id) {
      // ask confirmation to archive
      if (window.confirm('Are you sure you want to archive this item?')) {
        await setItemArchived(copyState.id, true);
        alert('Item Archived');
      }
    }
    if (totalCopies === 1) navigate('/landing');
    onUpdate();
  };

  return (
    <Box pb={3} ref={copyRef}>
      <Typography fontWeight={600} fontSize={'16px'}>
        {t('Copy Number')} {index + 1}
      </Typography>

      <QRCodeScanner
        id="shelf_input_qr"
        label={t('Shelf QR Code')}
        value={copyState.temporary_shelf_number}
        onChange={value => handleChange('shelf_input_qr', value)} // Call handleChange with name and value
        required={true}
        qrEnabled={qrEnabled}
        setQREnabled={setQREnabled}
        autofocus={true}
      />
      <QRCodeScanner
        id="accession_code_qr"
        label={t('Accession Code')}
        value={copyState.accession_code}
        onChange={value => handleChange('accession_code', value)} // Call handleChange with name and value
        required={true}
        qrEnabled={qrEnabled}
        setQREnabled={setQREnabled}
        autofocus={false}
      />
      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel
          sx={{ fontWeight: 400, color: '#949494' }}
          htmlFor="outlined-adornment-password"
        >
          {t('Call Number')}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          sx={{ background: 'white', borderRadius: '12px' }}
          value={copyState.temporary_accession_code || ''}
          onChange={e => handleChange(e.target.name, e.target.value)}
          name="temporary_accession_code"
          label={t('Call Number')}
          inputProps={{ maxLength: 15 }}
          disabled={disable}
        />
      </FormControl>
      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <AutoCompleteDropdown
          menuList={[t('Donated'), t('Purchased'), t('Borrowed')]}
          selectedOptions={copyState.source || ''}
          setSelectedOptions={value => handleChange('source', value)}
          disable={disable}
          label={t('Source')}
        />
      </FormControl>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              name="condition"
              checked={copyState.condition === 'Valuable'}
              onChange={event => {
                setCopyState(prev => ({
                  ...prev,
                  condition: event.target.checked ? 'Valuable' : '',
                }));
              }}
            />
          }
          label={t('Valuable')}
          disabled={disable}
          sx={{ px: 0, m: 0 }}
        />
        {!copy.archived && (
          <>
            <IconButton onClick={() => setDisable(false)}>
              <Edit />
            </IconButton>
            <IconButton
              onClick={() =>
                mutate({
                  mutationFn: handleArchive,
                  queryKeys: ['fetchInstances', 'fetchBookCount'],
                })
              }
            >
              <Archive />
            </IconButton>
          </>
        )}
      </Box>
      {copy.archived && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="37px"
          bgcolor="#FDE8C6"
          borderRadius="12px"
          p="8px"
          gap="4px"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#908056',
            marginTop: '8px',
          }}
        >
          {t('Archived')}
        </Box>
      )}
      {!disable && (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'right'}>
          <Button
            onClick={() =>
              mutate({
                mutationFn: handleSaveCopy,
                queryKeys: ['fetchInstances', 'fetchBookCount'],
              })
            }
          >
            {t('Save')}
          </Button>
          <Button onClick={() => setDisable(true)}>{t('Cancel')}</Button>
        </Box>
      )}
    </Box>
  );
};

export default CopyComponent;
