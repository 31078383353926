import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsData from './core-translations.json';
import faqTranslation from './faq-translations.json';

const languageMap = {
  en: 'English',
  bo: 'Tibetan',
};

interface TranslationDataItem {
  [languageCode: string]: string;
}
interface Translations {
  [languageCode: string]: Record<string, string>;
}

/**
 * Convert [{English:"e", Tibetan:"t"}] to {en:{"e":"e"}, bo:{"e":"t"}}
 */
function loadTranslationsToI18n(): Translations {
  const translations: Translations = {};

  Object.keys(languageMap).forEach(languageCode => {
    translations[languageCode] = {};
  });

  (translationsData as TranslationDataItem[]).forEach(item => {
    Object.entries(languageMap).forEach(([languageCode, languageName]) => {
      const translatedText = item[languageName];
      if (translatedText && item.English) {
        translations[languageCode][item.English] = translatedText;
      }
    });
  });

  return translations;
}

const coreTranslations = loadTranslationsToI18n();

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('lang') || 'en',
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
  resources: {
    en: {
      core: coreTranslations.en,
      faq: faqTranslation.en,
    },
    bo: {
      core: coreTranslations.bo,
      faq: faqTranslation.bo,
    },
  },
  ns: ['core', 'faq'],
  defaultNS: 'core',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
