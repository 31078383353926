import * as React from 'react';
import { Box, Typography, Avatar, Chip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface UserProps {
  userName: string;
  cataloguedItems: number;
  userId?: string;
  isActive: boolean;
}

const AssociatedLibrarian: React.FC<UserProps> = ({
  userId,
  userName,
  cataloguedItems,
  isActive,
}) => {
  // Handle cases where props are not provided
  const name = userName || 'Unknown User';
  const navigate = useNavigate();
  const itemsCount = cataloguedItems || 0;
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      py={1}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Avatar
          sx={{ bgcolor: '#F4F1E7', color: '#908056', fontFamily: 'serif' }}
        >
          {' '}
          {/* Avatar might change accordingly */}
          {name.slice(0, 2).toUpperCase()}
        </Avatar>
        <Box display={'flex'} flexDirection={'column'}>
          {!isActive &&
            <Typography fontWeight="400" fontSize="12px" color="#F95656">
              Deactivated
            </Typography>
          }
          <Typography fontWeight="400" fontSize="12px">
            {name}
          </Typography>
         
        </Box>
        
        <Chip
          label={String(itemsCount)}
          sx={{
            height: '24px',
            mx: 2,
            backgroundColor: '#D1DBE9',
            color: '#1458BD',
          }}
        />
      </Box>
      <IconButton
        onClick={() =>
          navigate(`/my-items?user_id=${userId}`, {
            state: { userId, userName, isActive },
          })
        }
        sx={{ paddingRight: 0 }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default AssociatedLibrarian;
