import { AuthenticatedApiHook } from '../types';
import {
  ADD_SCANNED_PAGE,
  CREATE_SCANNED_VERSION,
  GET_SCANNED_VERSION,
  GET_PRESIGNED_URL_ENDPOINT,
  LIST_SCANNED_VERSIONS,
  GET_INSTANCE_VERSION_DETAILS_ENDPOINT,
  GET_SCANNED_IMAGES_ENDPOINT,
} from '../utils/api';

export async function createScannedVersion(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string,
  library: string,
  pageCount: number
) {
  const response = await authenticatedApiHook(
    CREATE_SCANNED_VERSION,
    'POST',
    'application/json',
    { instance_id: instanceId, library, total_page_count: pageCount }
  );
  if (response) {
    return await response.json();
  }
}

export async function getScannedVersionDetails(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string,
  versionId: string
) {
  const url = `${GET_SCANNED_VERSION}?instance_id=${instanceId}&version_id=${versionId}`;
  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  }
}

export async function getVersionDetails(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string
) {
  const url = `${LIST_SCANNED_VERSIONS}?instance_id=${instanceId}`;
  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  }
}

export async function getInstanceVersionsDetails(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string
) {
  const url = `${GET_INSTANCE_VERSION_DETAILS_ENDPOINT}?instance_id=${instanceId}`;
  const response = await authenticatedApiHook(url);
  if (response) {
    return await response.json();
  }
}

export async function addScannedPage(
  authenticatedApiHook: AuthenticatedApiHook,
  version: string,
  bucketPath: string,
  fileName: string
) {
  const response = await authenticatedApiHook(
    ADD_SCANNED_PAGE,
    'POST',
    'application/json',
    { version, bucket_path: bucketPath, file_name: fileName }
  );
  if (response) {
    return await response.json();
  }
}

export async function getPresignedUrl(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string,
  versionId: string,
  fileName: string
) {
  try {
    const url = `${GET_PRESIGNED_URL_ENDPOINT}`;
    const method = 'POST';

    const requestBody = {
      instance_id: instanceId,
      filename: fileName,
      version: versionId,
    };
    const response = await authenticatedApiHook(
      url,
      method,
      undefined,
      requestBody
    );

    if (response) {
      return await response.json();
    }
  } catch (error) {
    console.error('Error in getPresignedUrl:', error);
    return null;
  }
}

export async function uploadToBucket(presignedUrl: string, file: File) {
  const method = 'PUT';
  const contentType = file.type;

  const headers = {
    'Content-Type': contentType, // Specify the file content type
  };

  const response = await fetch(presignedUrl, {
    method,
    headers,
    body: file, // Send the file as the request body
  });

  if (!response.ok) {
    const errorText = await response.text(); // Get the response text for more details
    throw new Error(
      `Upload failed with status: ${response.status}. Response: ${errorText}`
    );
  }

  return {
    success: true,
    url: presignedUrl.split('?')[0], // Removing the query parameters to get the base URL
  };
}

export async function getScannedImages(
  authenticatedApiHook: AuthenticatedApiHook,
  instanceId: string,
  versionId: number,
  offset: number
) {
  try {
    const url = `${GET_SCANNED_IMAGES_ENDPOINT}?instance_id=${instanceId}&version_id=${versionId}&offset=${offset}`;
    const method = 'GET';
    const response = await authenticatedApiHook(url, method);

    if (response) {
      return await response.json();
    }
  } catch (error) {
    console.error('Error in Scanned Images:', error);
    return null;
  }
}
